import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { HttpService } from '../http/http.service';
import { IUser } from 'app/models/user';
import { IStore } from 'app/models/store';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private httpService : HttpService
  ) { }

  getList() : Promise<any> {
    return this.httpService.get(`admin/clients/list`);
  }

}
