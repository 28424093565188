import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from './../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  private token : string = '';
  private id_cliente: string


  constructor(
    private storageService : StorageService
  ) {
    this.storageService.getToken()
    .subscribe((token : any) => {
      if(token != null && token != undefined) {
        this.token = token;
      }
    },(error: any) : any => {
      console.log(error);
    });

    this.id_cliente = localStorage.getItem('id_client') || '';
    console.log(this.id_cliente)
    console.log(typeof this.id_cliente)
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(".json") || req.url.includes("admin/files"))
    {
      return next.handle(req);
    }  
    this.id_cliente = localStorage.getItem('id_client') || '';

    let headers : any = {
      'Content-Type': `application/json`,
    };

    if (this.token) {
      headers.Authorization = `Bearer ${ this.token }`;
    }

    let request: any;
    if (req.method.toLowerCase() === 'post') {
      if (req.body instanceof FormData) {
        request =  req.clone({
          body: req.body.append("id_client", this.id_cliente)
        })
      } else {
        request = req.clone({
          setHeaders: headers,
          params: req.params.set(
            "id_client",
            this.id_cliente
          ),
        });
      }
    }
    else if (req.method.toLowerCase() === 'get' || req.method.toLowerCase() === 'put') {

      if(req.url.includes('admin/clients/list')){
        request = req.clone({
          setHeaders: headers,
        });
      }else{
        request = req.clone({
        setHeaders: headers,
        params: req.params.set(
          "id_client",
          this.id_cliente
        ),
      });
      }


    }

    return next.handle(request);
  }

}
