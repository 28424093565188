import { StorageService } from './../../services/storage/storage.service';
import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';
import { ClientsService } from "app/services/clients/clients.service";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { SpinnerService } from 'app/services/spinner/spinner.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
  // styles: ['.show { display: block; } .notShow { display: block; }'],
  animations: customAnimations
})

export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cliente_select') cliente_select: any;
  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  public listClients=[];
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  role: string = 'user';
  public storeID:string = "";
  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private cd: ChangeDetectorRef,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private spinner: SpinnerService,
    private clientService: ClientsService,
    private deviceService: DeviceDetectorService,
    private storageService : StorageService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();

    this.storageService.getRole()
    .subscribe((role: string) => {
      if(role) {
        this.role = role;
      }
    });
    
  
  }


  ngOnInit() {
    this.menuItems = ROUTES;

    this.menuItems.map( e => {
      const resul = e.role.filter(aux => { return aux == this.role.replace(/['"]+/g, '') });
      if (resul.length > 0) e.badgeClass = 'show';
      else e.badgeClass = 'notShow';
    });
    this.getListClients();

    
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = ROUTES;
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }
  getListClients(){
    this.clientService.getList().then(res => {
      console.log(res)
      this.listClients = res.response;
      let rol = JSON.parse(localStorage.getItem('role'));
    if (localStorage.getItem("id_client") === null && rol=="root") {
        localStorage.setItem('id_client', res.response[0].id)
      }
      this.cliente_select=localStorage.getItem("id_client");




      this.cd.detectChanges();
      this.cdr.markForCheck();
    })
    .catch(err => {
      console.log(err)
    })
    this.cd.detectChanges();
  }
  hideRoot(){
    console.log("root",this.role);
    return this.role=='"root"';
  }
  setId(id:string){

    localStorage.setItem('id_client', id)
    this.spinner.show();
 
    setTimeout(() => {
      location.reload();
    }, 1000);
  
   
  }
}
